<template>
    <div class="main-container" id="page">
    <!-- =========================
            HEADER 
        ============================== -->
        <header id="nav2-1">
            
            <nav class="navbar navbar-fixed-top" id="main-navbar">
            <!-- navbar fixed on top: -->
            <!--  
                <nav class="navbar navbar-fixed-top" id="main-navbar" role="navigation">
            -->
            <!-- navbar static: -->
            <!--
                <nav class="navbar navbar-static-top" id="main-navbar" role="navigation">
            -->
            <!-- background transparent: -->
            <!--
                <nav class="navbar navbar-fixed-top bg-transparent" id="main-navbar" role="navigation">
            -->
                
                <div class="container">
                    
                    <div class="navbar-header">
                        <!-- Menu Button for Mobile Devices -->
                        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        
                        <!-- Image Logo -->
                        <!-- note:
                            recommended sizes
                                width: 150px;
                                height: 35px;
                        -->
                        <a href="" class="navbar-brand smooth-scroll"><img src="images/uploads/8735b043-570d-4acf-a269-78a5dea79dc0.png" height="35" alt="logo" style="border-radius: 0px; border-color: rgb(67, 159, 224); border-style: none; border-width: 1px;"></a>
                        <!-- Image Logo For Background Transparent -->
                        <!--
                            <a href="#" class="navbar-brand logo-black smooth-scroll"><img src="images/logo-black.png" alt="logo" /></a>
                            <a href="#" class="navbar-brand logo-white smooth-scroll"><img src="images/logo-white.png" alt="logo" /></a> 
                        -->
                    </div><!-- /End Navbar Header -->

                    <div class="collapse navbar-collapse" id="navbar-collapse">
                        <!-- Menu Links -->
                        <ul class="nav navbar-nav navbar-right">
                            <li><a href="#features9-1" class="smooth-scroll" src="images/uploads/8735b043-570d-4acf-a269-78a5dea79dc0.png" style="font-weight: 700; font-size: 15px; text-transform: none;">Features</a></li>
                            <li><a href="#timeline1-1" class="smooth-scroll" src="images/uploads/8735b043-570d-4acf-a269-78a5dea79dc0.png" style="font-weight: 700; font-size: 15px; text-transform: none;">Get Started</a></li>
                            <li><a href="#testimonials3-2" class="smooth-scroll" src="images/uploads/8735b043-570d-4acf-a269-78a5dea79dc0.png" style="font-weight: 700; font-size: 15px; text-transform: none;">Testimonials</a></li>
                            <li><a href="https://vendorauto.com/doc" class="smooth-scroll" src="images/uploads/8735b043-570d-4acf-a269-78a5dea79dc0.png" style="font-weight: 700; font-size: 15px; text-transform: none;">Resources</a></li>
                            <li><a href="#pricing4-1" class="smooth-scroll" src="images/uploads/8735b043-570d-4acf-a269-78a5dea79dc0.png" style="font-weight: 700; font-size: 15px; text-transform: none;">Price</a></li>
                            <li><a href="#contact1-2" class="smooth-scroll" src="images/uploads/8735b043-570d-4acf-a269-78a5dea79dc0.png" style="font-weight: 700; font-size: 15px; text-transform: none;">Contact</a></li>
                            <li><a href="https://prodga.vendorauto.com" class="btn-nav btn-grey btn-login" style="font-weight: 700; font-size: 15px; background-color: rgb(232, 235, 240);">Login</a></li>
                            <li><a href="https://prodga.vendorauto.com/#/signUp" class="btn-nav btn-green btn-signup" style="font-weight: 700; font-size: 15px; background-color: rgb(50, 172, 117);">Signup</a></li>
                        </ul><!-- /End Menu Links -->
                    </div><!-- /End Navbar Collapse -->

                </div><!-- /End Container -->
            </nav><!-- /End Navbar -->
        </header><!-- =========================
            HERO SECTION
        ============================== -->
        <section id="hero4" class="p-b-0 bg-edit bg-dark" style="background-image: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),url('images/background.avif');background-size: cover; background-repeat: no-repeat;">
            <div class="container p-t-lg">
                <div class="row">
                    <div class="col-md-10 col-md-offset-1 text-center">
                        <h1 class="text-white m-b-md p-t-md">Streamline Order
Processing For
<font color="#ec7211">Amazon Vendor Central
Direct Fulfillment </font></h1>
                        <p class="lead text-white m-b-md f-w-700">
   <br>
Amazon Certified Public Developer for Vendor Central                     
                        <br>
Achieve <font color="#FFD700">Gold Tier Performance (DEA>96%) </font>and comply with Amazon's Stringent Policies 

<br> <font color="#ec7211"></font>

</p>
                        <a href="#contact1-2" class="btn btn-md btn-ghost text-uppercase" style="border-radius: 4px; font-size: 16px; background-color: rgba(255, 255, 255, 0.557); box-shadow: rgba(0, 0, 0, 0.043) 0px 1.0191px 1.69849px 0px inset; border-color: rgb(255, 255, 255); border-style: solid; border-width: 3px;">Request a Demo</a>
                        <a href="https://prodga.vendorauto.com/#/signUp" class="btn btn-md btn-green text-uppercase" style="border-radius: 4px; font-size: 16px; background-color: rgb(36, 168, 109); box-shadow: rgba(0, 0, 0, 0.063) 0px 1.465px 2.44166px 0px inset; border-color: rgb(36, 168, 109); border-style: solid; border-width: 3px;">14 Days Free Trial</a>
                    </div>
                </div><!-- /End Row -->
            </div><!-- /End Container -->
            <div class="container p-t-md">
                <div class="row">
                    <div class="col-sm-12">
                        <div id="carousel-dashboard" class="carousel slide carousel-fade carousel-dashboard" data-ride="carousel">
                            <!-- Indicators -->
                            <ol class="carousel-indicators">
                                <li data-target="#carousel-dashboard" data-slide-to="1" class="active"></li>
                            </ol>

                            <!-- Wrapper for slides -->
                            <div class="carousel-inner" role="listbox">
                         
                                <div class="item active">
                                    <img src="images/uploads/ScreenShotFirst.avif" class="img-responsive m-x-auto" alt="" style="border-radius: 0px; border-color: rgb(105, 105, 110); border-style: none; border-width: 1px;">
                                </div>
                            </div>
                        </div>
                    </div><!-- /End Col -->
                </div><!-- /End Row -->
            </div><!-- /End Container -->
        </section><!-- =========================
           VIDEO
        ============================== -->
        <section id="video4-1" class="p-y-md bg-edit bg-dark video content-align-md" style="background-color: rgb(86, 182, 139);">
            <div class="container">
                <div class="row ">    
                    <div class="col-md-12 y-middle c2">
                        <div class="col-md-6 text-white">
                            <h4>API Integration. Self Service. Easy setup without professional skills</h4>
                            <p class="p-opacity m-b-0"> Watch a Demo of VendorAuto
Integrate your Amazon Vendor Central Account within minutes</p>
                        </div>
                        <!-- Video Image with Popup -->
                        <div class="col-md-4 col-md-offset-1">
                            <div class="popup-box">
                                <img src="images/uploads/explainer.png" class="img-responsive" alt="" style="border-radius: 4px; border-color: rgb(105, 105, 110); border-style: none; border-width: 1px;">
                                <div class="popup-button wow pulse                               animated" data-wow-iteration="3" style="visibility: visible; animation-iteration-count: 3; animation-name: pulse;">
                                    <a class="iframe" @click="openWindow" href="javascript:;"><i class="fa fa-play" style="background-color: rgb(86, 182, 139);"></i></a>
                                </div>
                            </div>
                        </div>
                    </div><!-- /End Col-12 -->
                </div><!-- /End Row -->
            </div><!-- /End Container -->
        </section><!-- =========================
           FEATURES SECTION 
        ============================== -->
        <section id="features9-1" class="p-y-lg bg-edit" style="outline-offset: -3px;">
            
            <div class="container">
                <!-- Section header -->
                <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                        <div class="section-header text-center wow fadeIn" style="visibility: visible; animation-name: fadeIn;">
                            <h2>A Much Easier Way to Fulfill Orders</h2>
                            <p class="lead">Our approach is simple and iterative. In average, our customers save 4 hours processing Direct Fulfillment orders every day</p>    
                        </div>
                    </div>
                </div><!-- /End Section Header-->

                <div class="row text-center features-block c3">
                    <div class="col-md-4"> 
                        <i class="icon-refresh-2"></i>
                        <h4>Sync Orders</h4>
                        <p>VendorAuto automatically pull orders from Vendor Central periodically.</p>
                    </div>
                    <div class="col-md-4"> 
                        <i class="icon-cloud-download"></i>
                        <h4>Accept/Cancel</h4>
                        <p>You have the chance to either accept or cancel an order</p>
                    </div>
                    <div class="col-md-4"> 
                        <i class="icon-print"></i>
                        <h4>Print Labels</h4>
                        <p>With all the shipping labels in a single PDF, you can print it with any label printers.</p>
                    </div>
                </div><!-- /End Features-Block -->

                <div class="row text-center features-block c3 new-row-md">
                    <div class="col-md-4"> 
                        <i class="icon-presentation"></i>
                        <h4>Generate Report</h4>
                        <p>You can export the sales report as Excel file and import into your ERP system</p>
                    </div>
                    <div class="col-md-4"> 
                        <i class="icon-box1"></i>
                        <h4>Manage Inventory</h4>
                        <p>Update Direct Fulfillment inventory by importing an Excel template</p>
                    </div>
                    <div class="col-md-4"> 
                        <i class="icon-check"></i>
                        <h4>Confirm Shipment</h4>
                        <p>Easily end your work of the day by confirming the shipped orders</p>
                    </div>
                </div><!-- /End Features-Block -->
            </div><!-- /End Container -->

        </section><!-- =========================
           TIMELINE SECTION
        ============================== -->
       <section id="timeline1-1" class="p-y-lg bg-dark bg-edit" style="outline-offset: -3px;">
            <div class="container">
                <!-- Section Header -->
                <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                        <div class="section-header text-center text-white">
                            <h2>Quick Start and Self Service</h2>
                            <p class="lead">It only takes minutes to Get Started. No Developer Profile (DRAF) is Needed</p>
                        </div>
                    </div>
                </div>
                
                <!-- Timeline -->
                <div class="row">
                    <div class="col-md-6 col-md-offset-3 text-white">
                        <ul class="timeline">
                        
                            <li class="event" data-date="5 Minutes">
                                <h5 class="f-w-900">Sign Up</h5>
                                <p><a href="https://prodga.vendorauto.com/#/signUp">Sign up</a> VendorAuto to get 14 days free trial. No credit card is required</p>    
                            </li>
                            <li class="event" data-date="5 Minutes">
                                <h5 class="f-w-900">Create Instance</h5>
                                <p> <a href="https://vendorauto.com/doc/guide/amazon/CreateInstance.html">Create an instnace</a> for the E-commerce platform you are connecting to </p>
                            </li>
                            <li class="event" data-date="5 Minutes">
                                <h5 class="f-w-900">Authorize</h5>
                                <p> <a href="https://vendorauto.com/doc/guide/amazon/Authorize.html">Authorize</a> VendorAuto to access your Vendor Central orders with OAuth. No need to apply for Developer Profile </p>
                            </li>
                            <li class="event" data-date="5 Minutes ">
                                <h5 class="f-w-900">Import Products (Optional)</h5>
                                <p>Follow <a href="https://vendorauto.com/doc/guide/amazon/ImportProducts.html">Qucik Start</a> guide to import your prudcts</p>    
                            </li>
                        </ul>
                    </div>
                </div><!-- /End Timeline Row -->
            </div><!-- /End Container -->
        </section><!-- =========================
           FEATURES SECTION 
        ============================== -->
        <section id="features7-1" class="p-y-lg content-dashboard content-align-md bg-edit" style="outline-offset: -3px;">
            
            <div class="container">
                <div class="row features-block y-middle">
                    <!-- Image Device -->
                    <div class="col-md-7 col-md-push-5 text-center wow animated animated animated" style="visibility: visible;">
                        <img src="images/multi-box.avif" width="1000" alt="" style="border-radius: 0px; border-color: rgb(105, 105, 110); border-style: none; border-width: 1px;">
                    </div>
                    <!-- Features -->
                    <div class="col-md-5 col-md-pull-7 center-md">
                        <div class="col-xs-12 p-t m-b-md"> 
                            <h2 class="m-b">Multi-Box Shipment</h2>
                            <p>Customize dimensions and weight for
individual packages and generate
precise shipping labels effortlessly.</p>
                        </div>
                        <div class="col-xs-12 icon-left m-b-md clearfix"> 
                            <i class="icon-albums"></i>
                            <h5 class="m-t f-w-900">Multiple boxes for a SKU</h5>
                            <p>VendorAuto can print shipping labels of the number you specify for a SKU. Also you can set the dimension and weight for each box</p>
                        </div>
                        <div class="col-xs-12 icon-left m-b-md clearfix"> 
                            <i class="icon-albums"></i>
                            <h5 class="m-t f-w-900">Group items for a SKU to a box</h5>
                            <p>When multiple items for a SKU can fit into a box, VendorAuto will print one label for them. You can specify the number to group for a SKU</p>
                        </div>
                        <div class="col-xs-12 icon-left m-b-md clearfix"> 
                            <i class="icon-albums"></i>
                            <h5 class="m-t f-w-900"> Multi-line orders</h5>
                            <p>When an order has multiple lines, VendorAuto can print a label for each item</p>
                        </div>
                    </div><!-- /End Col -->
                </div><!-- /End Row -->
            </div><!-- /End Container -->

        </section><!-- =========================
           CONTENT SECTION
        ============================== -->
        <section id="content11-2" class="content-acc content-split bg-edit bg-dark" style="outline-offset: -3px;">
            <div class="container">
                <!-- Image Block -->
                <div class="col-md-6 img-block left-block">
                                        <img src="images/shelf.avif" class="img-responsive m-x-auto" alt="" style="border-radius: 0px; border-color: rgb(105, 105, 110); border-style: none; border-width: 1px;">

                </div>
                <div class="col-md-5 col-md-push-7 center-md text-white">
                        <div class="col-xs-12 p-t m-b-md"> 
                            <h2 class="m-b">Inventory Accuracy</h2>
                            <p>Maintain accurate stock levels with automatic updates to Amazon Vendor Central</p>
                        </div>
                        <div class="col-xs-12 acc-icon-left m-b-md clearfix"> 
                            <i class="icon-albums"></i>
                            <h5 class="m-t f-w-900">Streamline Inventory Control</h5>
                            <p>VendorAuto checks stock levels for each order, alerting you if inventory falls below zero, and automatically updates your stock for accepted orders. Simplify your inventory management with VendorAuto</p>
                        </div>
                        <div class="col-xs-12 acc-icon-left m-b-md clearfix"> 
                            <i class="icon-albums"></i>
                            <h5 class="m-t f-w-900">Update Amazon Invenvory</h5>
                            <p>VendorAuto Automatically update the stock level in your Amazon Vendor Central account </p>
                        </div>
                    </div>
                <!-- Conten Block -->
                <div class="col-md-5 col-md-push-7 content-block text-white">
                
                    
                </div>
            </div><!-- /End Container -->
        </section><!-- =========================
           FEATURES SECTION 
        ============================== -->
        <section id="features7-1" class="p-y-lg content-dashboard content-align-md bg-edit" style="outline-offset: -3px;">
            
            <div class="container">
                <div class="row features-block y-middle">
                    <!-- Image Device -->
                    <div class="col-md-7 col-md-push-5 text-center wow animated animated animated" style="visibility: visible;">
                        <img src="images/ATS.avif" width="1000" alt="" style="border-radius: 0px; border-color: rgb(105, 105, 110); border-style: none; border-width: 1px;">
                    </div>
                    <!-- Features -->
                    <div class="col-md-5 col-md-pull-7 center-md">
                        <div class="col-xs-12 p-t m-b-md"> 
                            <h2 class="m-b">Integrate to Amazon Transportation Service</h2>
                            <p>VendorAuto is the earliest service provider that integrates to Amazon Transportation Service (ATS). We have generated hundreds of thousands of ATS labels </p>
                        </div>
                        <div class="col-xs-12 icon-left m-b-md clearfix"> 
                            <i class="icon-albums"></i>
                            <h5 class="m-t f-w-900">100% Comply With ATS Requirement</h5>
                            <p>VendorAuto supports all ATS carries and makes sure every label printed correctly</p>
                        </div>
                        <div class="col-xs-12 icon-left m-b-md clearfix"> 
                            <i class="icon-albums"></i>
                            <h5 class="m-t f-w-900">Gold Tier Performance</h5>
                            <p>Confirm shipment with VendorAuto for all carriers. Guarantee to achieve Gold Tier Performance (DEA>96%) </p>
                        </div>
                    </div><!-- /End Col -->
                </div><!-- /End Row -->
            </div><!-- /End Container -->

        </section><!-- =========================
           CONTENT SECTION
        ============================== -->
        <section id="content11-2" class="content-split bg-edit bg-dark" style="outline-offset: -3px;">
            <div class="container">
                <!-- Image Block -->
                 
                <div class="col-md-6">
                                        <img src="images/boxes.avif" class="img-responsive m-x-auto" alt="" style="border-radius: 0px; border-color: rgb(105, 105, 110); border-style: none; border-width: 1px;">

                </div>                <!-- Conten Block -->

                
                <div class="col-md-5 col-md-push-2 content-block text-white">
                    <div class="vertical-center-rel">
                        <h3 class="f-w-900 m-b-md wow animated animated animated" style="visibility: visible;">Group Orders by SKUs</h3>
                        <p class="m-b-md">Orders will be grouped by categories, e.g. Warehouse, Shipby Date, Carrier, SKU. You can print Labels for different warehouses separately</p>
                        <a href="#contact1-2" class="btn btn-shadow btn-green smooth-scroll">Request Demo</a>
                    </div>
                </div>
            </div><!-- /End Container -->
        </section><!-- =========================
           CONTENT SECTION
        ============================== -->
        <section id="content5-1" class="p-y-lg content-block content-align-md bg-edit" style="outline-offset: -3px;">
            <div class="container">
                <div class="row y-middle c2">
                    <div class="col-md-5">
                        <h2 class="m-b-md">Print Labels to PDF</h2>
                        <p class="lead m-b-0">Deeply integrate into your business process. Group labels by warehouse, ship date, carrier and SKU. Mark each label with order details</p>
                    </div>
                    <div class="col-md-6">
                        <img src="images/uploads/list.png" class="img-responsive m-x-auto" alt="" style="border-radius: 0px; border-color: rgb(105, 105, 110); border-style: none; border-width: 1px;">
                    </div>
                </div><!-- /End Row -->
            </div><!-- /End Container-->

        </section><!-- =========================
             TESTIMONIAL
        ============================== -->
        <section id="testimonials3-2" class="p-y-lg bg-edit bg-blue" style="outline-offset: -3px; background-color: rgb(82, 94, 108);">
            <div class="container">
                <!-- Section Header -->
                <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                        <div class="m-b-md text-center text-white wow fadeIn                           animated" style="visibility: visible; animation-name: fadeIn;">
                            <h2>What People Are Saying About Us</h2>
                            <p class="lead">Meet our happy clients and find why our service is the preferred choice.</p>    
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 testimonials text-white">
                        <!-- Testimonial Item -->
                        <div class="col-md-4 text-center p-t-md  clearfix">
                            <blockquote class="quote-border">
                                <figure><img src="images/uploads/Acanva.png" class="img-circle" alt="" width="90" height="90"> </figure>
                                <p>We have beening selling through Amazon Vendor Central since 2012. We used to take 4-6 hours everyday to print labels, but currently with VendorAuto it only takes 15 minutes to print labels</p>
                                <div class="cite text-edit">
                                    Carol Li
                                    <span class="cite-info p-opacity ">CEO, Acanva CO.</span> 
                                </div>
                            </blockquote>
                        </div>
                        <!-- Testimonial Item -->
                        <div class="col-md-4 text-center p-t-md clearfix">
                            <blockquote class="quote-border">
                                <figure><img src="images/uploads/empava.jpg" alt="" class="img-circle" width="90" height="90"> </figure>
                                <p>Small vendors like us is looking for low-cost automation tools. The traditional EDI solutions usually have a high upfront cost and requires professional skills. VendorAuto is inexpensive and easy setup </p>
                                <div class="cite text-edit">
                                    Ricky Riley
                                    <span class="cite-info p-opacity">CEO, Empava</span>
                                </div>
                            </blockquote>
                        </div>
                        <!-- Testimonial Item -->
                        <div class="col-md-4 text-center p-t-md clearfix">
                            <blockquote class="quote-border">
                                <figure><img src="images/uploads/bvtech.png" alt="" class="img-circle" width="90" height="90"> </figure>
                                <p>We have been using VendorAuto for over 2 years. They always take actions quicly regarding our requests. They even have real-time support during weekend. We are very happy with the service</p>
                                <div class="cite text-edit">
                                    Robert Lawson 
                                    <span class="cite-info p-opacity">CTO, BVSecurity</span>
                                </div>
                            </blockquote>
                        </div>
                    </div><!-- /End Testimonial Col -->
                
                </div><!-- /End Row -->
            </div><!-- /End Container -->
        </section><!-- =========================
           PRICING
        ============================== -->
        <section id="pricing4-1" class="p-y-lg">
            <div class="container">
                <!-- Section Header -->
                <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                        <div class="section-header text-center wow fadeIn" style="visibility: visible; animation-name: fadeIn;">
                            <h2>Pay as you go. No hidden fees.</h2>
                            <p class="lead">VendorAuto has NO UPFRONT FEES and NO LONG-TERM CONTRACTS. We only charge based on the number of connected accounts and daily orders</p> 
                             <p class="lead">$5/Instance/Month</p> 
                        </div>
                    </div>
                </div>

                <div class="row pricing-3po">
                    <!-- Pricing Table 1 -->
                    <div class="col-md-4 green">
                        <div class="info text-center">
                            <h5 class="m-b-md">Bracket 1</h5>
                            <h4 class="m-b-md">1-150 Orders</h4>
                            <div class="price text-edit"> 
                            <span class="currency">¢</span>24
                            </div>
                           <ul class="details m-b-md text-edit">
                                <li> Per Order and Per Day</li>
                            </ul>
                        </div>
                    </div>
                    <!-- Pricing Table 2 -->
                    <div class="col-md-4 green">
                        <div class="info text-center">
                             <h5 class="m-b-md">Bracket 2</h5>
                            <h4 class="m-b-md">151-300 Orders</h4>
                            <div class="price text-edit"> <span class="currency">¢</span>22</div>
                              <ul class="details m-b-md text-edit">
                                <li> Per Order and Per Day</li>
                            </ul>
                        </div>
                    </div>
                    <!-- Pricing Table 3 -->
                    <div class="col-md-4 green">
                        <div class="info text-center">
                             <h5 class="m-b-md">Bracket 3</h5>
                            <h4 class="m-b-md">301-400 Orders</h4>
                            <div class="price text-edit"> <span class="currency">¢</span>20</div>
                             <ul class="details m-b-md text-edit">
                                <li> Per Order and Per Day</li>
                            </ul>
                         </div>
                    </div>
                </div><!-- /End Row -->
                 <div class="row pricing-3po">
                    <!-- Pricing Table 1 -->
                    <div class="col-md-4 green">
                        <div class="info text-center">
                              <h5 class="m-b-md">Bracket 4</h5>
                            <h4 class="m-b-md">401-500 Orders</h4>
                            <div class="price text-edit"> <span class="currency">¢</span>18</div>
                             <ul class="details m-b-md text-edit">
                                <li> Per Order and Per Day</li>
                            </ul>
                        </div>
                    </div>
                    <!-- Pricing Table 2 -->
                    <div class="col-md-4 green">
                        <div class="info text-center">
                              <h5 class="m-b-md">Bracket 5</h5>
                            <h4 class="m-b-md">501-600 Orders</h4>
                            <div class="price text-edit"> <span class="currency">¢</span>16</div>
                             <ul class="details m-b-md text-edit">
                                <li> Per Order and Per Day</li>
                            </ul>
                        </div>
                    </div>
                    <!-- Pricing Table 3 -->
                    <div class="col-md-4 green">
                        <div class="info text-center">
                              <h5 class="m-b-md">Bracket 6</h5>
                            <h4 class="m-b-md">600-3,000 Orders</h4>
                            <div class="price text-edit"> <span class="currency">¢</span>14</div>
                             <ul class="details m-b-md text-edit">
                                <li> Per Order and Per Day</li>
                            </ul>
                        </div>
                    </div>
                    
                
                </div> 
                
                <div class="row pricing-3po">
                   
                    <!-- Pricing Table 3 -->
                    <div class="col-md-12 green">
                        <div class="info text-center">
                              <h5 class="m-b-md"></h5>
                            <h4 class="m-b-md">More than 3000 Orders Per Day</h4>

                            <div class="price text-edit">                            <h3 class="m-b-md">Contact Us</h3>
 </div>
<p></p>
                            
                        </div>
                    </div>
                     <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                        <div class="section-header text-center wow fadeIn" style="visibility: visible; animation-name: fadeIn;">
                             <p class="lead">More details about pricing can be found <a href="https://vendorauto.com/doc/guide/NewPricing.html">here</a></p> 
                        </div>
                    </div>
                </div>
                
                </div><!-- /End Row -->
            </div><!-- /End Container -->
        </section>
       <!-- =========================
             CONTACT FORM
        ============================== -->
   <contact></contact>
       <!-- =========================
             FOOTER
        ============================== -->
        <footer id="footer2-2" class="p-y footer f2 bg-edit bg-dark">
            <div class="container">
                <div class="row text-white">
                    <div class="col-sm-4 col-xs-12">
                        <p>Copyright © 2020 ChenyueSoft</p>
                    </div>
                    <div class="col-sm-4 col-xs-12 text-center">
                        <ul class="footer-social">
                          
                        </ul>
                    </div>
                    <div class="col-sm-4 col-xs-12">
                        <ul class="footer-links">
                            <li><a href="" title="" class="edit inverse">Privacy Policy</a></li>
                            <li><a href="mailto:cgao@vendorauto.com" class="edit inverse" src="images/uploads/8735b043-570d-4acf-a269-78a5dea79dc0.png" style="color: rgb(255, 255, 255); font-weight: 700; font-size: 15px; text-transform: none;"><i class="fa fa-envelope-o"></i>Get in touch</a></li>
                        </ul>
                    </div>
                </div><!-- /End Row -->
            </div><!-- /End Container -->

        </footer>
            <VideoDialog ref="videoDialog"></VideoDialog>
        </div><!-- /End Main Container -->
</template>

<script>
import contact from "./components/contact.vue"
import VideoDialog from './components/VideoDialog'

export default {
  name: "App",
  components: {
    contact,
    VideoDialog
  },
  methods:{
      openWindow(){
          this.$refs.videoDialog.open({
              type:'iframe',
              code: `<iframe width=1120" height="630" src="https://www.youtube.com/embed/VA7HM5-CTjg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
          })
      }
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.acc-icon-left{
    position: relative;
    padding-left: 80px;
}
.acc-icon-left .icon-albums{
    position: absolute;
    left: 0;
    top: 10px;
    /* top: 50%;
    transform: translateY(-50%); */
    font-size: 50px;
}
.content-split .img-block img{
    max-height: 100%;
}
@media screen and (min-width: 992px) {
    .content-acc .col-md-6{
        display: flex;
        align-items: center;
    }
    .content-acc .col-md-5{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .content-acc .container{
        display: flex;
    }
}


</style>
