<template>
    <transition name="fade">
        <div class="dialog-video" v-if="visible">
            <div ref="inner" class="inner" :style="innerStyle">
                <div v-html="code" v-if="type=='iframe'"></div>
                <video  class="video" :src="src" v-if="type=='video'"></video>
            </div>
            <div class="btn-close" @click="close"></div>
            <div class="cover"></div>
        </div>
    </transition>
</template>
<script>
export default{
    data(){
        return {
            visible: false,
            src:"",
            title:"视频",
            type: '', //iframe or video
            code: "",
            innerStyle:{}
        }
    },
    methods:{
        open(opts){
            this.type = opts.type
            this.code = opts.code
            this.src = opts.src
            this.visible=true
            setTimeout(()=>{
                this.resize()   
            })
        },
        close(){
            this.visible=false;
            this.innerStyle = {}
            this.$refs.inner.classList.remove('fixed')
        },
        resize(){
            const winDom = document.documentElement || document.body
            const winW = winDom.offsetWidth
            const frameW = this.$refs.inner.offsetWidth,
                  frameH = this.$refs.inner.offsetHeight
            if(frameW > winW){
                this.$refs.inner.classList.add('fixed')
                this.innerStyle={
                    width: winW+'px',
                    height: winW * frameH / frameW + 'px'
                }
                console.log(this.innerStyle,'this.innerStyle')
            }
        }
    }
}
</script>
<style scoped>
.dialog-video{position:fixed;overflow: auto;width:100%;height:100%;top:0;left:0;background: rgba(0,0,0,.7);z-index: 1900;}
.dialog-video .inner{position:absolute;display: inline-block;top:50%;left:50%;transform: translate(-50%,-50%);}
.btn-close{position: absolute;top:20px;right:20px;width: 50px;height: 50px;display:block;background:#000;border-radius: 50%;cursor: pointer;opacity: .6;}
.btn-close:before,.btn-close:after{content:"";display: block;width: 30px;height:1px;background:#fff;position:absolute;top:50%;left:10px;transform: rotate(45deg)}
.btn-close:after{transform: rotate(-45deg)}
.btn-close:hover{opacity: 1}

.video{max-width:90%;}
.dialog-video .inner.fixed > div{width:100%;height:100%;position: relative;}
.dialog-video .inner.fixed /deep/ iframe{max-width: 100%;max-height: 100%;}
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.fade-enter-active {
  transition: all .3s ease;
}
.fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-enter, .fade-leave-to{
  transform: scale(.5);
  opacity: 0;
}
</style>