         <template>
        <section id="contact1-2" class="p-y-lg contact bg-edit bg-dark" style="outline-offset: -3px;">
            <div class="container">
                <!-- Section Header -->
                <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                        <div class="section-header text-center text-white wow fadeIn" style="visibility: visible; animation-name: fadeIn;">
                            <h2>Ready to Get Started?</h2>
                            <p class="lead">Schedule a demo now</p>    
                        </div>
                    </div>
                </div>
                <!-- Contact Form -->
                <div class="row">
                    <div class="col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3">
                        <form class="form-horizontal form-white" id="contactForm" onsubmit="return false">
                            <!-- Notifications -->
                            
                            <p v-if="resStatus=='success'" class="success cf text-center"><i class="fa fa-check"></i> <strong> Message sent successfully. We will contact you in 24 hours. </strong></p>
                            <p v-if="resStatus=='error'" class="failed cf text-center"><i class="fa fa-exclamation-circle"></i><strong> Something went wrong!</strong></p>

                            <div class="form-group">
                                <input 
                                    class="form-control"
                                    v-model="name"
                                    id="cfName"
                                    type="text"
                                    placeholder="Name"
                                > 
                            </div>
                            <div class="form-group">
                                  <input 
                                    class="form-control"
                                    v-model="email"
                                    id="cfEmail"
                                    type="email"
                                    placeholder="Email"
                                > 
                            </div>
                            <div class="form-group">
                                 <input 
                                    class="form-control"
                                    v-model="subject"
                                    id="cfSubject"
                                    type="text"
                                    placeholder="Subject"
                                > 
                            </div>
                            <div class="form-group">
                                 <textarea 
                                    class="form-control"
                                    rows="5"
                                    v-model="message"
                                    id="cfMessage"
                                    placeholder="Your Message"
                                > </textarea>
                            </div> 
                            <div class="form-group m-b-0">
                                <button                                 
                                    v-on:click="sendemail"
                                    id="sendEmail2"
                                    type="submit" class="btn btn-shadow btn-green">CONTACT US!</button>
                            </div>
                        </form>
                    </div>
                </div><!-- /End Contact Form -->
            
            </div><!-- /End Container -->
            <div class="loading" :class="{open: loading}">
              <div class="inner">
                <div class="img"><img src="/images/loading.svg" /></div>
                <div class="msg">Loading</div>
              </div>
              <div class="cover"></div>
            </div>
        </section>
</template>

<script>
// import Message from "vue-m-message"
import awsconfig from "../aws-exports"
import axios from "axios"
const url = awsconfig.aws_cloud_logic_custom[0].endpoint + "/actions/sendemail"
export default {
  name: "SignUp",

  data: function() {
    return {
      name: "",
      email: "",
      subject: "",
      message:"",
      loading: false,
      resStatus: ''
    }
  },
  methods: {
    sendemail: function() {
      var thisObject = this
      var postData = JSON.stringify({
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message
      })
      this.resStatus = ""
      if (!this.email || !this.name) {
        
        this.resStatus = "error"
        return
      }
      this.loading = true
      axios
        .post(url, {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message
        })
        .then((response)=> {
          console.log(response)
          thisObject.resStatus = "success"
          // thisObject.$alert(
          //   "We have received your request. We will contact you soon"
          // )
          thisObject.name = ""
          thisObject.email = ""
          thisObject.subject = ""
          thisObject.message = ""
        })
        .catch(function(error) {
          console.log(error)
          thisObject.resStatus = "error"
        }).finally(()=>{
          thisObject.loading = false
        })
      console.log(postData)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.success,.failed{
  display: block!important;
}
.loading{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 900;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
}
.loading.open{
  opacity: 1;
  visibility: visible;
}
.loading .cover{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,.3);
}
.loading .inner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.9);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
.loading img{
  width: 40px;
  margin-bottom: 5px;;
}
</style>
